<template>
  <div class="NotificationSetting" v-loading="loadings">
    <div class="dw-card">
      <div class="title">{{$t('lang.SText90')}}</div>
      <div class="main">
        <div class="main-item">
          <el-checkbox-group v-model="checkListAccount">
            <!-- <el-checkbox
              class="Notification-checkbox"
              label="Website notification"
            ></el-checkbox>
            <el-checkbox
              class="Notification-checkbox"
              label="E-mail notification"
            ></el-checkbox> -->
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in AccountList"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="dw-card">
      <div class="title">{{$t('lang.QText86')}}</div>
      <div class="main">
        <div class="main-item">
          <div class="main-item-title">
            <div class="title-text">{{$t('lang.SText91')}}</div>
          </div>
          <el-checkbox-group v-model="checkListMyaccount">
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in MyAccountListQ"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="title-text">{{$t('lang.SText92')}}</div>
            <div class="people" @click="openDialog('Quotation', 0)">
              <svg-icon icon-class="userHead"></svg-icon>{{ mystaffQ }}
            </div>
          </div>
          <el-checkbox-group v-model="checkListMystaff">
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in MystaffListQ"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="title-text">{{$t('lang.SText93')}}</div>
            <div class="people" @click="openDialog('Quotation', 1)">
              <svg-icon icon-class="userHead"></svg-icon>{{ myparterQ }}
            </div>
          </div>
          <el-checkbox-group v-model="checkListMyparter">
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in MyparterListQ"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="dw-card">
      <div class="title">{{$t('lang.BText35')}}</div>
      <div class="main">
        <div class="main-item">
          <div class="main-item-title">
            <div class="title-text">{{$t('lang.SText91')}}</div>
          </div>
          <el-checkbox-group v-model="checkListMyaccountB">
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in MyAccountListB"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="title-text">{{$t('lang.SText92')}}</div>
            <div class="people" @click="openDialog('Booking', 2)">
              <svg-icon icon-class="userHead"></svg-icon>{{ mystaffB }}
            </div>
          </div>
          <el-checkbox-group v-model="checkListMystaffB">
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in MystaffListB"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="title-text">{{$t('lang.SText93')}}</div>
            <div class="people" @click="openDialog('Booking', 3)">
              <svg-icon icon-class="userHead"></svg-icon>{{ myparterB }}
            </div>
          </div>
          <el-checkbox-group v-model="checkListMyparterB">
            <el-checkbox
              class="Notification-checkbox"
              :label="item.id"
              :key="index + Math.random()"
              v-for="(item, index) in MyparterListB"
              @change="
                ($event) => {
                  handlecheckListAccount($event, item);
                }
              "
              >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="dw-card">
      <div class="title">{{$t('lang.SText94')}}</div>
      <div class="main">
        <div class="main">
          <div class="main-item">
            <div class="main-item-title">
              <div class="title-text">{{$t('lang.SText91')}}</div>
            </div>
            <el-checkbox-group v-model="checkListMyaccountT">
              <el-checkbox
                class="Notification-checkbox"
                :label="item.id"
                :key="index + Math.random()"
                v-for="(item, index) in MyAccountListT"
                @change="
                  ($event) => {
                    handlecheckListAccount($event, item);
                  }
                "
                >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="main-item">
            <div class="main-item-title">
              <div class="title-text">{{$t('lang.SText92')}}</div>
              <div class="people" @click="openDialog('Tracking', 4)">
                <svg-icon icon-class="userHead"></svg-icon>{{ mystaffT }}
              </div>
            </div>
            <el-checkbox-group v-model="checkListMystaffT">
              <el-checkbox
                class="Notification-checkbox"
                :label="item.id"
                :key="index + Math.random()"
                v-for="(item, index) in MystaffListT"
                @change="
                  ($event) => {
                    handlecheckListAccount($event, item);
                  }
                "
                >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="main-item">
            <div class="main-item-title">
              <div class="title-text">{{$t('lang.SText93')}}</div>
              <div class="people" @click="openDialog('Tracking', 5)">
                <svg-icon icon-class="userHead"></svg-icon>{{ myparterT }}
              </div>
            </div>
            <el-checkbox-group v-model="checkListMyparterT">
              <el-checkbox
                class="Notification-checkbox"
                :label="item.id"
                :key="index + Math.random()"
                v-for="(item, index) in MyparterListT"
                @change="
                  ($event) => {
                    handlecheckListAccount($event, item);
                  }
                "
                >{{ item.sendType =='Website notification'?$i18n.locale == 'zh_cn'?'站内通知':'Website notification':$i18n.locale == 'zh_cn'?'邮件通知':'E-mail notification' }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="editFlag" width="70%" class="createDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="dialog-title1">
        <div class="left">
          <svg-icon
            icon-class="remind"
            class="svg-icon"
            style="color: '#FFF'"
          ></svg-icon>
          {{$t('lang.BTextNotification')}} - {{ title1 }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="dialog-main">
        <div class="dialog-main-title">
          {{$t('lang.SText95')}}
        </div>
        <div class="dialog-main-content">
          <div class="user">
            <div class="usertitle">{{$t('lang.SText96')}}</div>
            <div class="userList">
              <el-cascader
                :options="options"
                :props="props"
                :placeholder="$t('lang.BText80')"
                collapse-tags
                clearable
                v-model="value"
                @change="changeUserList"
                ><template slot-scope="{ node, data }">
                  <div class="line1">{{ data.label }}</div>
                  <div v-if="data.email" class="line2">{{ data.email }}</div>
                </template>
              </el-cascader>
            </div>
          </div>
          <div class="userView">
            <div class="usertitle">
              <div class="title">{{$t('lang.SText97')}}</div>
              <div class="number">
                {{ this.userList.length + this.userList1.length }}
              </div>
            </div>
            <div class="userList">
              <span
                v-for="(item, index) in userList1"
                :key="'userList1' + index"
                >{{ item.label }}；</span
              >
              <span
                v-for="(item, index) in userList2"
                :key="'userList0' + index"
                >{{ item.label }}；</span
              >
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-button type="primary" @click="applyDialog()">{{$t('lang.BText120')}}</el-button>
          <el-button type="info" @click="closeDialog()">{{$t('lang.QText79')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadings: true,
      value: [],
      checkList: [],
      checkListAccount: [],
      checkListMyparter: [],
      checkListMystaff: [],
      checkListMyaccount: [],
      checkListMyparterB: [],
      checkListMystaffB: [],
      checkListMyaccountB: [],
      checkListMyparterT: [],
      checkListMystaffT: [],
      checkListMyaccountT: [],
      AccountList: [],
      mystaffQ: "",
      myparterQ: "",
      mystaffB: "",
      myparterB: "",
      mystaffT: "",
      myparterT: "",
      MyAccountListQ: [],
      MystaffListQ: [],
      MyparterListQ: [],
      MyAccountListB: [],
      MystaffListB: [],
      MyparterListB: [],
      MyAccountListT: [],
      MystaffListT: [],
      MyparterListT: [],
      editFlag: false,
      title1: null,
      title2: null,
      props: { multiple: true },
      userList: [],
      userList1: [],
      options: [],
      newData: [],
      userList2: [],
      userinfo: {},
      token: "",
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userinfo = value;
    });
    this.$session("token").get((value) => {
      this.token = value;
    });
  },
  mounted() {
    this.notificationSettings();
  },
  methods: {
    handlecheckListAccount(event, val) {
      if (`${event}` == "true") {
        val.state = "0";
      } else {
        val.state = "1";
      }
      let data = [
        {
          id: val.id,
          state: val.state,
          peopleList: val.peopleList ? JSON.stringify(val.peopleList) : "",
        },
      ];
      this.$ajax
        .cPost(this.source.notification_api.dwBmsNotificationSettings, {
          paramStr: JSON.stringify(data),
        })
        .then((res) => {
          if (res.data.result == "true") {
            // this.notificationSettings()
            // this.$notify({
            //   title: res.data.message,
            //   type: "success",
            //   offset: 100,
            // });
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
        });
    },
    applyDialog() {
      var data = this.userList1.concat(this.userList2);
      if (this.title2 == "0") {
        this.MystaffListQ.forEach((x) => {
          this.newData.push({
            id: x.id,
            state: x.state,
            peopleList: JSON.stringify(data),
          });
        });
      } else if (this.title2 == "1") {
        this.MyparterListQ.forEach((x) => {
          this.newData.push({
            id: x.id,
            state: x.state,
            peopleList: JSON.stringify(data),
          });
        });
      } else if (this.title2 == "2") {
        this.MystaffListB.forEach((x) => {
          this.newData.push({
            id: x.id,
            state: x.state,
            peopleList: JSON.stringify(data),
          });
        });
      } else if (this.title2 == "3") {
        this.MyparterListB.forEach((x) => {
          this.newData.push({
            id: x.id,
            state: x.state,
            peopleList: JSON.stringify(data),
          });
        });
      } else if (this.title2 == "4") {
        this.MystaffListT.forEach((x) => {
          this.newData.push({
            id: x.id,
            state: x.state,
            peopleList: JSON.stringify(data),
          });
        });
      } else if (this.title2 == "5") {
        this.MyparterListT.forEach((x) => {
          this.newData.push({
            id: x.id,
            state: x.state,
            peopleList: JSON.stringify(data),
          });
        });
      }
      this.$ajax
        .cPost(this.source.notification_api.dwBmsNotificationSettings, {
          paramStr: JSON.stringify(this.newData),
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.editFlag = false;
            this.notificationSettings();
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
          } else {
            this.editFlag = true;
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
        });
    },
    notificationSettings() {
      this.loadings = true;
      this.AccountList = [];
      this.MyAccountListQ = [];
      this.MystaffListQ = [];
      this.MyparterListQ = [];
      this.MyAccountListB = [];
      this.MystaffListB = [];
      this.MyparterListB = [];
      this.MyAccountListT = [];
      this.MystaffListT = [];
      this.MyparterListT = [];
      this.checkListAccount = [];
      this.checkListMyaccount = [];
      this.checkListMystaff = [];
      this.checkListMyparter = [];
      this.checkListMyaccountB = [];
      this.checkListMystaffB = [];
      this.checkListMyparterB = [];
      this.checkListMyaccountT = [];
      this.checkListMystaffT = [];
      this.checkListMyparterT = [];
      this.$ajax
        .cPost(this.source.notification_api.listData, {})
        .then((res) => {
          if (res.data.result == "true") {
            this.loadings = false;
            if (res.data.data) {
              res.data.data.forEach((res) => {
                if (res.notificationType == "Account") {
                  res.personnelType = "";
                }
                if (res.notificationType == "Account") {
                  this.AccountList.push(res);
                  if (res.state == "0") this.checkListAccount.push(res.id);
                } else if (
                  res.notificationType == "Quotation" &&
                  res.personnelType == "My account"
                ) {
                  this.MyAccountListQ.push(res);
                  if (res.state == "0") this.checkListMyaccount.push(res.id);
                } else if (
                  res.notificationType == "Quotation" &&
                  res.personnelType == "My staff"
                ) {
                  this.MystaffListQ.push(res);
                  this.mystaffQ = this.MystaffListQ.length?this.MystaffListQ[0].number:'0';
                  if (res.state == "0") this.checkListMystaff.push(res.id);
                } else if (
                  res.notificationType == "Quotation" &&
                  res.personnelType == "My parter"
                ) {
                  this.MyparterListQ.push(res);
                  this.myparterQ = this.MyparterListQ.length?this.MyparterListQ[0].number:'0';
                  if (res.state == "0") this.checkListMyparter.push(res.id);
                } else if (
                  res.notificationType == "Booking" &&
                  res.personnelType == "My account"
                ) {
                  this.MyAccountListB.push(res);
                  if (res.state == "0") this.checkListMyaccountB.push(res.id);
                } else if (
                  res.notificationType == "Booking" &&
                  res.personnelType == "My staff"
                ) {
                  this.MystaffListB.push(res);
                  this.mystaffB = this.MystaffListB.length?this.MystaffListB[0].number:'0';
                  if (res.state == "0") this.checkListMystaffB.push(res.id);
                } else if (
                  res.notificationType == "Booking" &&
                  res.personnelType == "My parter"
                ) {
                  this.MyparterListB.push(res);
                  this.myparterB = this.MyparterListB.length?this.MyparterListB[0].number:'0';
                  if (res.state == "0") this.checkListMyparterB.push(res.id);
                } else if (
                  res.notificationType == "Tracking" &&
                  res.personnelType == "My account"
                ) {
                  this.MyAccountListT.push(res);
                  if (res.state == "0") this.checkListMyaccountT.push(res.id);
                } else if (
                  res.notificationType == "Tracking" &&
                  res.personnelType == "My staff"
                ) {
                  this.MystaffListT.push(res);
                  this.mystaffT = this.MystaffListT.length?this.MystaffListT[0].number:'0';
                  if (res.state == "0") this.checkListMystaffT.push(res.id);
                } else if (
                  res.notificationType == "Tracking" &&
                  res.personnelType == "My parter"
                ) {
                  this.MyparterListT.push(res);
                  this.myparterT = this.MyparterListT.length?this.MyparterListT[0].number:'0';
                  if (res.state == "0") this.checkListMyparterT.push(res.id);
                }
              });
            }
          } else {
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
            this.loadings = false;
            this.AccountList = [];
            this.MyAccountListQ = [];
            this.MystaffListQ = [];
            this.MyparterListQ = [];
            this.MyAccountListB = [];
            this.MystaffListB = [];
            this.MyparterListB = [];
            this.MyAccountListT = [];
            this.MystaffListT = [];
            this.MyparterListT = [];
            this.checkListAccount = [];
            this.checkListMyaccount = [];
            this.checkListMystaff = [];
            this.checkListMyparter = [];
            this.checkListMyaccountB = [];
            this.checkListMystaffB = [];
            this.checkListMyparterB = [];
            this.checkListMyaccountT = [];
            this.checkListMystaffT = [];
            this.checkListMyparterT = [];
          }
        });
    },
    getParterNameList() {
      this.options = [];
      this.$ajax
        .cPost(this.source.notification_api.getPartnerNames, {})
        .then((res) => {
          if (res.data.result == "true") {
            if(res.data.data[0].children){
              res.data.data[0].children.forEach(x=>{
                console.log(this.userList1)
                this.userList1.forEach(y=>{
                  if(x.value == y.value){
                    x.disabled = true
                  }
                })
              })
            }
            this.options = res.data.data;
          } else {
            this.options = [];
          }
        });
    },
    getStaffNameList() {
      this.options = [];
      this.$ajax
        .cPost(this.source.notification_api.getStaffNames, {})
        .then((res) => {
          if (res.data.result == "true") {
            if(res.data.data[0].children){
              res.data.data[0].children.forEach(x=>{
                this.userList1.forEach(y=>{
                  if(x.value == y.value){
                    x.disabled = true
                  }
                })
              })
            }
            this.options = res.data.data;
          } else {
            this.options = [];
          }
        });
    },
    openDialog(title, title2) {
      this.newData = [];
      this.userList1 = [];
      this.userList = [];
      this.userList2 = [];
      this.value = [];
      this.editFlag = true;
      this.title1 = title;
      this.title2 = title2;
      if (this.title2 == "0") {
        this.userList1 = this.MystaffListQ.length
          ? this.MystaffListQ[0].nameList
          : [];
        this.getStaffNameList();
      } else if (this.title2 == "1") {
        this.userList1 = this.MyparterListQ.length
          ? this.MyparterListQ[0].nameList
          : [];
        this.getParterNameList();
      } else if (this.title2 == "2") {
        this.userList1 = this.MystaffListB.length
          ? this.MystaffListB[0].nameList
          : [];
        this.getStaffNameList();
      } else if (this.title2 == "3") {
        this.userList1 = this.MyparterListB.length
          ? this.MyparterListB[0].nameList
          : [];
        this.getParterNameList();
      } else if (this.title2 == "4") {
        this.userList1 = this.MystaffListT.length
          ? this.MystaffListT[0].nameList
          : [];
        this.getStaffNameList();
      } else if (this.title2 == "5") {
        this.userList1 = this.MyparterListT.length
          ? this.MyparterListT[0].nameList
          : [];
        this.getParterNameList();
      }
    },
    closeDialog() {
      this.editFlag = false;
    },
    changeUserList(e) {
      this.userList = [];
      this.userList2 = [];
      e.forEach((item, index) => {
        this.userList.push(item[1]);
        this.options[0].children.forEach((x) => {
          if (item[1] == x.value) {
            this.userList2.push({ label: x.label, value: x.value });
          }
        });
      });
    },
    getCheckedNodes(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.NotificationSetting {
  width: 860px;
  margin: auto;
  .el-dialog {
    margin-top: 0 !important;
    height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
  .dialog-main {
    padding: 40px 80px;
    position: relative;
    height: 700px;
    .dialog-main-title {
      font-size: 30px;
      color: $mainColor;
      border-bottom: 2px solid $mainColor;
      padding-bottom: 30px;
      text-align: left;
    }
    .dialog-main-content {
      display: flex;
      padding-top: 40px;
      height: 400px;
    }

    .user {
      width: 50%;
      text-align: left;
    }

    .userView {
      width: 50%;
      text-align: left;
      .usertitle {
        border-bottom: 1px solid $borderColor1;
      }
    }

    .usertitle {
      font-size: 20px;
      color: $fontColor2;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }
    .userList {
      font-size: 20px;
      color: $mainColor;
      padding-top: 20px;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .main-item {
    width: 310px;
    text-align: left;
    margin-bottom: 30px;
    .main-item-title {
      display: flex;
      padding-bottom: 10px;
      justify-content: space-between;
      border-bottom: 1px solid $borderColor1;
      .title-text {
        font-size: 30px;
        color: $fontColor3;
      }
      .people {
        display: flex;
        color: $fontColor3;
        line-height: 28px;
        cursor: pointer;
        height: 28px;
        margin-top: 5px;

        .svg-icon {
          margin-right: 2px;
          fill: $mainColor;
          stroke: $mainColor;
        }
      }
    }
    .el-checkbox-group {
      margin-top: 10px;
    }
    .Notification-checkbox {
      line-height: 40px;
      color: $mainColor;
      overflow: hidden;
      .el-checkbox__input {
        // margin-top: -8px;
      }

      .el-checkbox__label {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}
</style>